import { useState } from "react";

import { useSelector } from "react-redux";

import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";
import { PhoneNumberUtil } from 'google-libphonenumber';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

import { PulseLoader } from 'react-spinners';
import useAuth from '../hooks/useAuth';

import { useTranslation } from "react-i18next";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
};

const LoginModal = ({  }) => {

    const { t } = useTranslation();

    const { loginMessage } = useSelector((state) => state.data);
    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState('');
    const [ countryData, setCountryData ] = useState(null);
    const [ message, setMessage ] = useState("");
    const isValid = isPhoneValid(phone);

    const { register, authModalOpen, setAuthModalOpen } = useAuth();

    const onClose = () => {
        setAuthModalOpen(0);
    }

    return (
        <Modal open={authModalOpen === 1} onClose={onClose} center showCloseIcon={false} classNames={{ modal: " rounded-lg !bg-[#FFFFFF] w-[335px] min-h-[450px] lg:min-w-[420px] lg:w-[420px] lg:min-h-[460px] !p-0" }} >
            <div className="flex flex-col gap-6 p-[50px]">

                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-6 right-6 cursor-pointer" onClick={onClose}>
                    <path d="M8.5859 10.0001L0.792969 2.20718L2.20718 0.792969L10.0001 8.58582L17.793 0.792969L19.2072 2.20718L11.4143 10.0001L19.2072 17.7929L17.793 19.2072L10.0001 11.4143L2.20718 19.2072L0.792969 17.7929L8.5859 10.0001Z" fill="black"/>
                </svg>

                <div className="w-[100px] h-[100px] bg-[#942CC71A] rounded-full mx-auto flex items-center justify-center">
                    <svg width="28" height="40" viewBox="0 0 28 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 4V36H24V4H4ZM2 0H26C27.1046 0 28 0.89544 28 2V38C28 39.1046 27.1046 40 26 40H2C0.89544 40 0 39.1046 0 38V2C0 0.89544 0.89544 0 2 0ZM14 30C15.1046 30 16 30.8954 16 32C16 33.1046 15.1046 34 14 34C12.8954 34 12 33.1046 12 32C12 30.8954 12.8954 30 14 30Z" fill="#942CC7"/>
                    </svg>
                </div>
                
                <div className=" text-xl font-bold text-[#000] text-center">{t('what_is_your_phone_number')}</div>
                <div className="text-base font-light text-[#000] text-center">
                    {   
                        loginMessage === '' && 
                        t('please_confirm_your_country_code_and_enter_your_phone_number')
                    }
                    {
                        loginMessage !== '' && 
                        loginMessage
                    }
                </div>

                <div className='flex flex-col gap-1'>
                    <PhoneInput
                        defaultCountry="il"
                        value={phone}
                        onChange={(phone, meta) => {
                            setPhone(phone);
                            setCountryData(meta?.country);
                        }}
                        inputClassName="w-full !h-[55px] !text-[16px] !font-medium !rounded-tr-lg !rounded-br-lg"
                        style={
                            {
                            // style with css variables or with ".react-international-phone-flag-emoji" class
                            '--react-international-phone-flag-width': '32px',
                            '--react-international-phone-flag-height': '32px',
                            'direction': 'ltr'
                            }
                        }
                        countrySelectorStyleProps={{
                            buttonClassName: "!h-[55px] !w-[80px] !rounded-tl-lg !rounded-bl-lg"
                        }}
                    />
                    {!isValid && <div className=' text-red-600 text-center'>{t('phone_is_not_valid')}</div>}
                    {message && <div className=' text-red-600 text-center'>{ message }</div>}
                </div>


                <button 
                    className='bg-[#160F41] h-[50px] text-white leading-6 font-normal text-[16px] rounded-lg disabled:bg-opacity-60 flex justify-center items-center' 
                    disabled={!isValid || loading}
                    onClick={async () => {
                        setLoading(true);
                        setMessage("");
                        const res = await register(phone, countryData);
                        setLoading(false);
                        if(res.status === "error")
                        {
                            setMessage(res.message);
                        } else {
                            // navigate("/verify");
                            setAuthModalOpen(2);
                        }
                    }}
                >
                    {
                        loading ? 
                        <PulseLoader
                            loading={loading}
                            color='#FFFFFF'
                            size={12}
                        />
                        :
                        t('send_verification_code')
                    }
                    
                </button>

            </div>
        </Modal>
    )
}

export default LoginModal;