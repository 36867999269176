import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";

import { useTranslation } from "react-i18next";
import { useRef, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";

const PolicyModal = ({ open, onClose, onConfirm }) => {

    const { t } = useTranslation();
    const { user } = useAuth();
    const divRef = useRef(null);
    const [isBottom, setIsBottom] = useState(false);
    const [isChecked, setIsChecked] = useState(!!user?.policy_accept);
    const [hasVerticalScroll, setHasVerticalScroll] = useState(false);

    const handleScroll = (e) => {
        const specialDiv = e.target;
        if (specialDiv) {
            const { scrollTop, scrollHeight, clientHeight } = specialDiv;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                setIsBottom(true);
            }
        }
    };

    useEffect(() => {
        const checkScroll = () => {
          const element = divRef.current;
          if (element) {
            const hasVertical = element.scrollHeight > element.clientHeight;
            setHasVerticalScroll(hasVertical);
          }
        };
        
        setTimeout(() => {
            checkScroll(); // Check initially
        }, 1000)
    
        window.addEventListener('resize', checkScroll); // Check on window resize
        return () => window.removeEventListener('resize', checkScroll);
      }, []);

    return (
        <Modal open={open} onClose={() => {}} center showCloseIcon={false} classNames={{ modal: "rounded-[20px] lg:rounded-lg !bg-[#F7F6FD] w-[335px] lg:w-[660px] h-[616px] !p-0" }} >
            <div className="flex flex-col w-full h-full relative">

                <div className="flex-grow px-[30px] lg:px-[50px] pt-[30px] lg:pt-[50px] w-full h-full flex flex-col gap-[30px]">
                    <div className="border-b border-[#DDDDDD] relative flex">
                        <div className="border-b-[3px] border-[#952DD1] font-bold text-base lg:text-2xl text-center flex flex-col gap-5">
                            { t('privacy_policy') }
                            <div></div>
                        </div>

                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-0 ltr:right-0 rtl:left-0 cursor-pointer" onClick={onClose}>
                            <path d="M8.5859 10.0001L0.792969 2.20718L2.20718 0.792969L10.0001 8.58582L17.793 0.792969L19.2072 2.20718L11.4143 10.0001L19.2072 17.7929L17.793 19.2072L10.0001 11.4143L2.20718 19.2072L0.792969 17.7929L8.5859 10.0001Z" fill="black"/>
                        </svg>
                    </div>

                    <div className={`overflow-y-auto ${user?.id && !user?.policy_accept ? "h-[350px]": "h-[400px]"} p-2 w-full`} onScroll={(e) => handleScroll(e)} ref={divRef}>
                        <div className="text-[#716F74] text-xs lg:text-base font-medium text-wrap w-full text-justify" dangerouslySetInnerHTML={{__html: t('policy')}}>
                        </div>
                    </div>

                    {
                        (user?.id && !user?.policy_accept) && 
                        <div className="flex gap-4">
                            <input type="checkbox" checked={isChecked} onChange={(e) => setIsChecked((old) => !old)} disabled={!isBottom} />
                            <span>{t('agree_policy')}</span>
                        </div>
                    }
                </div>

                <div className="flex min-h-[70px] border-t border-[#DDDDDD]">
                    <button className="text-[#942CC7] text-base font-normal w-full" 
                        disabled={( user?.id ? !isChecked : false )}
                        onClick={() => {
                            onClose()
                            onConfirm()
                        }}
                    >
                        { t('confirm') }
                    </button>
                    <span className="border-r border-[#DDDDDD]"></span>
                    <button
                        className="w-full text-base font-normal"
                        onClick={onClose}
                    >
                        { t('cancel') }
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default PolicyModal;