import { Fragment, useEffect, useState } from 'react';
import { useNavigate, Link, useLocation, useParams, matchPath } from 'react-router-dom';
import { Menu, Transition, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { useSelector, useDispatch } from 'react-redux';

import { LANGUAGE, LOCALE, BACKEND_URL } from '../../utils/type';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { setSelectPurchaseModalOpen, setSettings } from '../../features/slices/dataSlice';

import PolicyModal from '../../components/PolicyModal';
import ConfirmModal from '../../components/ConfirmModal';
import ProfileModal from '../../components/ProfileModal';
import ContactUsModal from '../../components/ContactUsModal';
import LoginModal from '../../components/LoginModal';
import VerifyModal from '../../components/VerifyModal';
import SelectPurchaseModal from '../../components/SelectPurchaseModal';
import ConnectCardModal from '../../components/ConnectCardModal';
import BillCompleteModal from '../../components/BillCompleteModal';


import axiosServices from '../../utils/axios';
import PurchaseConfirmModal from '../../components/PurchaseConfirmModal';
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import DefaultAvatar from '../../assets/img/avatar.jpg';

import UKFlag from '../../assets/img/uk.png';
import ILFlag from '../../assets/img/il.png';
import UAEFlag from '../../assets/img/uae.png';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}


const Header = () => {
    const navigate = useNavigate();
    const { selectPurchaseModalOpen, settings } = useSelector((state) => state.data);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [locale, setLocale] = useState(localStorage.getItem("locale") || "en");
    const [langIndex, setLangIndex] = useState(0);
    const [langChanged, setLangChanged] = useState(0);
    const [mdoalOpen, setModalOpen] = useState(0);
    const [confirmModalOpen, setConfirmModalOpen] = useState(0);
    const [profileModalOpen, setProfileModalOpen] = useState(0);
    const [contactUsModalOpen, setContactUsModalOpen] = useState(0);
    const { logout, user, updateProfile, finishQuestion, reduceBalance, questionPurchase, setAuthModalOpen } = useAuth();
    const [balance, setBalance] = useState("$0");
    const location = useLocation();

    const [selectedPurchaseIndex, setSelectedPurchaseIndex] = useState(0);
    const [openCardConnectModal, setOpenCardConnectModal] = useState(0);
    const [billCompleteModalOpen, setBillCompleteModalOpen] = useState(0);


    useEffect(() => {
        finishQuestion();

        const searchParams = new URLSearchParams(location.search);
        const loginParam = searchParams.get('login');
        if( loginParam == 1 ) {
            setAuthModalOpen(1);
        }

        const fetchSettings = async () => {
            const { data } = await axiosServices.get('settings');
            dispatch(setSettings(data));
        }

        fetchSettings();
    }, [location])

    useEffect(() => {
        if (locale === "en") {
            setLangIndex(0)
        } else if (locale === "he") {
            setLangIndex(1)
        } else if (locale === "ar") {
            setLangIndex(2)
        }
    }, [locale]);

    useEffect(() => {
        if (localStorage.getItem("locale") !== LOCALE[langIndex] && langChanged) {
            localStorage.setItem("locale", LOCALE[langIndex]);
            navigate(`/home`);
            window.location.reload();
        }
    }, [langChanged, langIndex])

    useEffect(() => {
        // setModalOpen(!user?.policy_accept)

        const fetchBalance = async (user_id) => {
            try {
                const { data } = await axiosServices.get(`transactionApi/getBalance?user_id=${user_id}`);
                setBalance(<div className="flex">$<span className="flex" style={{ direction: 'ltr' }}>{(Number(data?.amount) / 100).toFixed(2)}</span></div>);
            } catch (error) {

            }
        }

        if (user?.id) {
            fetchBalance(user?.id);
        }

    }, [user, questionPurchase])

    const handleLogout = () => {
        setConfirmModalOpen(1);
    }

    const handlePolicy = () => {
        setModalOpen(1);
    }

    const handleReadPolicy = async () => {
        if( !user?.id ) {
            setModalOpen(0);
            return;
        }

        if (user?.policy_accept === 1) {
            setModalOpen(0);
            return;
        }
        const res = await axiosServices.patch(`user/${user?.id}`, {
            policy_accept: 1
        });

        if (res?.data?.status === "success") {
            updateProfile(res?.data?.user);
            localStorage.setItem("auth", JSON.stringify(res?.data?.user));
            setModalOpen(0);
        }
    }





    // Deposite Balance
    const onPurchaseIndex = (index) => {
        setSelectedPurchaseIndex(index);
    }

    const onSelectPurchaseItem = () => {
        dispatch(setSelectPurchaseModalOpen(false));
        setOpenCardConnectModal(true);
    }

    return (
        <div className="lg:h-[100px] lg:border-b lg:border-[#DDDDDD] w-[335px] lg:w-full flex items-start lg:items-center justify-between mx-auto">
            <div className='flex flex-col lg:flex-row lg:items-center lg:px-[50px] lg:gap-[50px] h-full'>
                <Logo className="w-[50px] h-[34px] lg:w-[92px] lg:h-[60px] my-5 cursor-pointer" onClick={() => navigate(`/home`)} />
                <span className='hidden lg:block h-full border-r border-[#DDDDDD]'></span>
                <h1 className="text-base lg:text-[30px] font-medium">{ t('welcome_to_dali') }</h1>
            </div>
            <div className='flex flex-col-reverse lg:flex-row lg:items-center lg:px-[50px] gap-5 lg:gap-[30px] h-full'>
                {
                    user?.id && 
                    <div className="flex flex-row-reverse lg:flex-row items-center gap-5">
                        <button 
                            className="bg-[#160F41] h-10 lg:h-[50px] text-white leading-6 font-normal text-sm lg:text-base rounded-lg disabled:bg-opacity-60 flex justify-center items-center px-4 lg:px-7 text-nowrap"
                            onClick={() => dispatch(setSelectPurchaseModalOpen(true))}
                        >
                        {t(`add_money`)}
                        </button>

                        <span className='text-[#942CC7] font-medium text-sm lg:text-xl '>
                            {balance}
                        </span>
                    </div>
                }
                <span className='hidden lg:block h-full border-r border-[#DDDDDD]'></span>
                <div className='flex items-center justify-end gap-2.5 mt-5 lg:mt-0'>

                    <Link className="w-[42px] h-[42px] lg:w-14 lg:h-14 rounded-full bg-white hidden lg:flex items-center justify-center" to={`/home`}>
                        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 20.0001H4C3.44772 20.0001 3 19.5524 3 19.0001V10.0001H0L10.3273 0.611625C10.7087 0.264875 11.2913 0.264875 11.6727 0.611625L22 10.0001H19V19.0001C19 19.5524 18.5523 20.0001 18 20.0001ZM5 18.0001H17V8.15757L11 2.70302L5 8.15757V18.0001ZM7 14.0001H15V16.0001H7V14.0001Z" fill="#942CC7"/>
                        </svg>
                    </Link>

                    {
                        user?.id && 
                        <Menu as="div" className="relative inline-block text-left">
                            <MenuButton className="w-8 h-8 lg:w-14 lg:h-14 rounded-full bg-white flex items-center justify-center">
                                {
                                    !user?.avatar ?
                                    <img src={DefaultAvatar} className="w-[22px] h-[22px] lg:w-10 lg:h-10 rounded-full cursor-pointer" />
                                    :
                                    <img src={`${BACKEND_URL}/storage/${user?.avatar}`} className="w-[22px] h-[22px] lg:w-10 lg:h-10 rounded-full cursor-pointer" /*onClick={() => setProfileModalOpen(1)}*/ alt='' />
                                }
                            </MenuButton>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <MenuItems className={`absolute ${langIndex === 0 ? "right-0": "left-0"} z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                                    <div className="py-5 flex flex-col gap-5">
                                        <MenuItem >
                                            {({ active }) => (
                                                <a href="#"
                                                    className="text-sm lg:text-base font-light px-4 hover:text-[#942CC7] flex"
                                                    onClick={() => {
                                                        setProfileModalOpen(1);
                                                    }}
                                                > {t(`edit_profile`)} </a>
                                            )}
                                        </MenuItem>
                                        <MenuItem >
                                            {({ active }) => (
                                                <a href="#"
                                                    className="text-sm lg:text-base font-light px-4 hover:text-[#942CC7] flex"
                                                    onClick={() => {
                                                        setContactUsModalOpen(true);
                                                    }}
                                                > {t(`contact_us`)} </a>
                                            )}
                                        </MenuItem>
                                        <MenuItem >
                                            {({ active }) => (
                                                <a href="#"
                                                    className="text-sm lg:text-base font-light px-4 hover:text-[#942CC7] flex"
                                                    onClick={() => {
                                                        handlePolicy();
                                                    }}
                                                > {t(`privacy_policy`)} </a>
                                            )}
                                        </MenuItem>
                                        <MenuItem className="block lg:hidden">
                                            {({ active }) => (
                                                <a href={`/home`}
                                                    className="text-sm lg:text-base font-light px-4 hover:text-[#942CC7] flex"
                                                > {t(`home`)} </a>
                                            )}
                                        </MenuItem>
                                    </div>
                                </MenuItems>
                            </Transition>
                        </Menu>
                    }

                    {
                        !user?.id && 
                        <Menu as="div" className="relative inline-block text-left">
                            <MenuButton className="w-[32px] h-[32px] lg:w-14 lg:h-14 rounded-full bg-white flex items-center justify-center">
                                <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg" className="size-4 lg:size-7">
                                    <path d="M0 21C0 16.5817 3.58172 13 8 13C12.4183 13 16 16.5817 16 21H14C14 17.6863 11.3137 15 8 15C4.68629 15 2 17.6863 2 21H0ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM8 10C10.21 10 12 8.21 12 6C12 3.79 10.21 2 8 2C5.79 2 4 3.79 4 6C4 8.21 5.79 10 8 10Z" fill="#942CC7"/>
                                </svg>
                            </MenuButton>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <MenuItems className={`absolute ${langIndex === 0 ? "right-0": "left-0"} z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                                    <div className="py-1">
                                        <MenuItem >
                                            {({ active }) => (
                                                <a href="#"
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'flex px-4 py-2 text-sm'
                                                    )}
                                                    onClick={() => {
                                                        setAuthModalOpen(1);
                                                    }}
                                                >
                                                    {t(`login_as_a_student`)}
                                                </a>
                                            )}
                                        </MenuItem>
                                        <MenuItem >
                                            {({ active }) => (
                                                <a
                                                    href="https://dali-center.com/?loginModalShow=1"
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'flex px-4 py-2 text-sm'
                                                    )}
                                                >
                                                    {t(`login_as_a_teacher`)}
                                                </a>
                                            )}
                                        </MenuItem>
                                        <MenuItem >
                                            {({ active }) => (
                                                <a href="#"
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'flex px-4 py-2 text-sm'
                                                    )}
                                                    onClick={() => {
                                                        setContactUsModalOpen(true);
                                                    }}
                                                > {t(`contact_us`)} </a>
                                            )}
                                        </MenuItem>
                                        <MenuItem >
                                            {({ active }) => (
                                                <a href="#"
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'flex px-4 py-2 text-sm'
                                                    )}
                                                    onClick={() => {
                                                        handlePolicy();
                                                    }}
                                                > {t(`privacy_policy`)} </a>
                                            )}
                                        </MenuItem>
                                    </div>
                                </MenuItems>
                            </Transition>
                        </Menu>
                    }
                    
                    <Menu as="div" className="relative inline-block text-left">

                        <MenuButton className="flex items-center gap-2.5">
                            <button className="w-[32px] h-[32px] lg:w-14 lg:h-14 rounded-full bg-white flex items-center justify-center">
                                {
                                    langIndex === 0 && 
                                    <img src={UKFlag} alt="" className="w-[22px] h-[22px] lg:w-10 lg:h-10 rounded-full" />
                                }
                                {
                                    langIndex === 1 && 
                                    <img src={ILFlag} alt="" className="w-[22px] h-[22px] lg:w-10 lg:h-10 rounded-full" />
                                }
                                {
                                    langIndex === 2 && 
                                    <img src={UAEFlag} alt="" className="w-[22px] h-[22px] lg:w-10 lg:h-10 rounded-full" />
                                }
                            </button>
                            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.20256 0.666796L10.7976 0.666797C11.2209 0.666797 11.5867 0.909297 11.7509 1.2993C11.9159 1.69013 11.8342 2.12013 11.5392 2.42346L7.33339 6.7468C6.67172 7.4843 5.34006 7.49596 4.65589 6.7343L0.460892 2.42346C0.165892 2.12096 0.0842252 1.69013 0.249225 1.2993C0.413392 0.909296 0.779224 0.666796 1.20256 0.666796Z" fill="#942CC7"/>
                            </svg>

                        </MenuButton>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <MenuItems className={`absolute ${langIndex === 0 ? "right-0": "left-0"} z-10 mt-2 w-24 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                                <div className="py-1">
                                    {
                                        LANGUAGE?.map((item, index) => (
                                            <MenuItem key={index}>
                                                {({ active }) => (
                                                    <a
                                                        href="#"
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            `flex px-4 py-2 text-sm ${ settings?.langs?.indexOf(LOCALE[index]) === -1 ? "hidden" : ""}`
                                                        )}
                                                        onClick={() => {
                                                            setLangIndex(index)
                                                            setLangChanged(1);
                                                        }}
                                                    >
                                                        {item}
                                                    </a>
                                                )}
                                            </MenuItem>
                                        ))
                                    }
                                </div>
                            </MenuItems>
                        </Transition>
                    </Menu>
                    {
                        user?.id && 
                        <button className="w-[32px] h-[32px] lg:w-14 lg:h-14 rounded-full bg-white flex items-center justify-center" onClick={() => handleLogout()}>
                            <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="size-4 lg:size-7">
                                <path d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C13.2713 0 16.1757 1.57078 18.0002 3.99923L15.2909 3.99931C13.8807 2.75499 12.0285 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C12.029 18 13.8816 17.2446 15.2919 15.9998H18.0009C16.1765 18.4288 13.2717 20 10 20ZM17 14V11H9V9H17V6L22 10L17 14Z" fill="#942CC7"/>
                            </svg>
                        </button>
                    }
                </div>
            </div>


            <PolicyModal
                open={mdoalOpen}
                onClose={() => setModalOpen(0)}
                onConfirm={handleReadPolicy}
            />
            <ConfirmModal
                open={confirmModalOpen}
                onClose={() => setConfirmModalOpen(0)}
                onConfirm={() => {
                    navigate(`/home`);
                    logout()
                }}
            />
            <ProfileModal
                open={profileModalOpen}
                onClose={() => setProfileModalOpen(0)}
                onConfirm={() => { }}
            />
            <ContactUsModal
                open={contactUsModalOpen}
                onClose={() => setContactUsModalOpen(0)}
                onConfirm={() => { }}
            />

            <PurchaseConfirmModal
                open={questionPurchase}
                onClose={() => reduceBalance()}
                onConfirm={() => {
                    reduceBalance();
                }}
                // content={questionPurchase?.content}
                // title={questionPurchase?.title}
                content={t(`your_balance_will_reduce`, {questionCount: questionPurchase?.questionCount, price: questionPurchase?.price, amount: questionPurchase?.amount})}
                title={t(`you_answered__counts_questions`, {questionCount: questionPurchase?.questionCount})}
                isHideCancel={true}
            />

            <LoginModal />
            <VerifyModal />



            <SelectPurchaseModal
                open={selectPurchaseModalOpen}
                onClose={(e) => {
                    dispatch(setSelectPurchaseModalOpen(false));
                }}
                selectedIndex={selectedPurchaseIndex}
                setIndex={onPurchaseIndex}
                onConfirm={onSelectPurchaseItem}
            />

            <ConnectCardModal
                open={openCardConnectModal}
                onClose={(status) => {
                    setOpenCardConnectModal(0);
                    if (status === "success") {
                        setBillCompleteModalOpen(1);
                    } else if (status === "error") {
                        setBillCompleteModalOpen(2);
                    } else {
                        setBillCompleteModalOpen(status);
                    }
                }}
                priceIndex={selectedPurchaseIndex}
            />

            <BillCompleteModal
                open={billCompleteModalOpen}
                onClose={() => setBillCompleteModalOpen(0)}
            />
        </div>
    )
}

export default Header;