import { createSlice } from '@reduxjs/toolkit'

export const dataSlice = createSlice({
  name: 'data',
  initialState: {
    subjects: [], 
    headerText: '', 
    lectureData: null, 
    lectureIndex: 0, 

    selectedRecommended: 0, 
    selectedMostViewed: 0, 
    loading: false, 
    settings: {}, 
    selectPurchaseModalOpen: 0, 
    loginMessage: '',
  },
  reducers: {
    setSubjects: (state, action) => {
      state.subjects = action.payload;
    }, 
    setHeaderText: (state, action) => {
      state.headerText = action.payload;
    }, 
    setLectureData: (state, action) => {
      state.lectureData = action.payload;
    }, 
    setLectureIndex: (state, action) => {
      state.lectureIndex = action.payload;
    }, 
    setSelectedRecommended: (state, action) => {
      state.selectedRecommended = action.payload;
    }, 
    setSelectedMostViewed: (state, action) => {
      state.selectedMostViewed = action.payload;
    }, 
    setLoading: (state, action) => {
      state.loading = action.payload;
    }, 
    setSettings: (state, action) => {
      state.settings = action.payload;
    }, 
    setSelectPurchaseModalOpen: (state, action) => {
      state.selectPurchaseModalOpen = action.payload;
    }, 
    setLoginMessage: (state, action) => {
      state.loginMessage = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setSubjects, setHeaderText, setLectureData, setLectureIndex, setSelectedRecommended, setSelectedMostViewed, setLoading, setSettings, setSelectPurchaseModalOpen, setLoginMessage } = dataSlice.actions

export default dataSlice.reducer