import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";

import { useTranslation } from "react-i18next";

const PurchaseConfirmModal = ({ open, onClose, onConfirm, title = '', content = '', isHideCancel = false }) => {

    const { t } = useTranslation();

    return (
        <Modal open={open} onClose={onClose} center showCloseIcon={false} classNames={{ modal: "rounded-[30px] !bg-[#FFFFFF] w-[330px] lg:min-w-[330px] lg:w-[330px] h-auto !p-0" }} >
            <div className="flex flex-col justify-between h-full">
                {
                    !isHideCancel && 
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-5 right-5 cursor-pointer" onClick={onClose}>
                        <path d="M8.5859 10.0001L0.792969 2.20718L2.20718 0.792969L10.0001 8.58582L17.793 0.792969L19.2072 2.20718L11.4143 10.0001L19.2072 17.7929L17.793 19.2072L10.0001 11.4143L2.20718 19.2072L0.792969 17.7929L8.5859 10.0001Z" fill="black"/>
                    </svg>
                }

                <div className="flex-grow flex flex-col items-center justify-center gap-5 mt-14 mb-4 px-[30px]">
                    <div className="text-xl font-bold  text-center">
                        { title }
                    </div>
                    <div className="text-base font-light">{ content }</div>
                </div>

                <div className="flex h-[70px] border-t border-[#DDDDDD]">
                    <button className="text-[#942CC7] text-base font-normal w-full !outline-none" 
                        onClick={onConfirm}
                    >
                        { t('confirm') }
                    </button>
                    {
                        !isHideCancel && 
                        <span className="border-r border-[#DDDDDD]"></span>
                    }
                    {
                        !isHideCancel && 
                        <button
                            className="w-full text-base font-normal"
                            onClick={onClose}
                        >
                            { t('cancel') }
                        </button>
                    }
                </div>
            </div>
        </Modal>
    )
}

export default PurchaseConfirmModal;