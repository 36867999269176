import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { PRICE_LIST } from "../utils/type";

const SelectPurchaseModal = ( { open, onClose, selectedIndex, setIndex, onConfirm } ) => {

    const { t } = useTranslation();
    const { settings } = useSelector((state) => state.data);

    return (
        <Modal open={open} onClose={onClose} center showCloseIcon={false} classNames={{ modal: " rounded-[30px] bg-[#F7F6FD] !p-0 w-[335px] lg:w-[660px] h-[452px] !m-0" }}>
            <div className="flex flex-col gap-6 justify-between h-full">
                <div className="flex-grow px-10 lg:px-[50px] pt-[50px] w-full h-full flex flex-col gap-5 lg:gap-[30px]">

                    <div className="border-b border-[#DDDDDD] relative flex">
                        <div className="border-b-[3px] border-[#952DD1] font-normal text-xl lg:text-2xl text-center flex flex-col gap-4 lg:gap-5">
                            {t(`select_amount`)}
                            <div></div>
                        </div>

                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-0 ltr:right-0 rtl:left-0 cursor-pointer" onClick={onClose}>
                            <path d="M8.5859 10.0001L0.792969 2.20718L2.20718 0.792969L10.0001 8.58582L17.793 0.792969L19.2072 2.20718L11.4143 10.0001L19.2072 17.7929L17.793 19.2072L10.0001 11.4143L2.20718 19.2072L0.792969 17.7929L8.5859 10.0001Z" fill="black"/>
                        </svg>
                    </div>

                    <div className="text-base font-normal -mt-5 flex">
                    {t(`please_select_the_amount_that_you_want_to_deposit`)}
                    </div>

                    <div className="flex-grow grid grid-cols-2 gap-4">

                    {
                        Object.keys(settings).length > 0 && 
                        PRICE_LIST?.map((item, index) => (
                            <div className={`rounded-lg border-[#952DD1] border flex gap-2 py-3 items-center justify-center w-full text-base font-light cursor-pointer ${index === selectedIndex ? "bg-[#952DD1] text-white": "text-black"}`} 
                                key={index}
                                onClick={() => setIndex(index)}
                            >
                                {`$ ${item} (+$${settings[`${item}_bonus`]})`}
                            </div>
                        ))
                    }

                    </div>

                </div>
 
                <div className="flex min-h-[70px] border-t border-[#DDDDDD]">
                    <button className="text-[#942CC7] text-base font-normal w-full" 
                        onClick={onConfirm}

                    >
                        { t('confirm') }
                    </button>
                    <span className="border-r border-[#DDDDDD]"></span>
                    <button
                        className="w-full text-base font-normal"
                        onClick={onClose}
                    >
                        { t('cancel') }
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default SelectPurchaseModal;