import { useState } from "react";
import "react-responsive-modal/styles.css";
import Modal from "react-responsive-modal";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import useAuth from "../hooks/useAuth";
import axiosServices from "../utils/axios";

import 'react-image-upload/dist/index.css'

const ContactUsModal = ( { open, onClose, onConfirm } ) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { user, updateProfile } = useAuth();
    const [ phone, setPhone ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ title, setTitle ] = useState("");
    const [ content, setContent ] = useState("");


    const handleSendContactUs = async () => {

        try {
            const res = await axiosServices.postForm(`mailApi`, {
                phone, 
                email, 
                title, 
                content,
                id: user?.id
            });

            if(res?.data?.status === "success") {
                toast.success(t('will_message_to_you'), {
                    autoClose: 3000,
                });
                setTimeout(() => {
                    onClose();
                    navigate("/home");
                }, 2000)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Modal open={open} onClose={onClose} center showCloseIcon={false} classNames={{ modal: "rounded-[20px] lg:rounded-lg bg-[#F7F6FD] !p-0 w-[335px] lg:w-[660px] h-[665px]" }}>
            <div className="flex flex-col gap-6 justify-between h-full">
                <div className="flex-grow px-[30px] lg:px-[50px] pt-[30px] lg:pt-[50px] w-full h-full flex flex-col gap-[30px]">

                    <div className="border-b border-[#DDDDDD] relative flex">
                        <div className="border-b-[3px] border-[#952DD1] font-bold text-2xl text-center flex flex-col gap-5">
                            {t(`contact_us`)}
                            <div></div>
                        </div>

                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute top-0 ltr:right-0 rtl:left-0 cursor-pointer" onClick={onClose}>
                            <path d="M8.5859 10.0001L0.792969 2.20718L2.20718 0.792969L10.0001 8.58582L17.793 0.792969L19.2072 2.20718L11.4143 10.0001L19.2072 17.7929L17.793 19.2072L10.0001 11.4143L2.20718 19.2072L0.792969 17.7929L8.5859 10.0001Z" fill="black"/>
                        </svg>
                    </div>

                    <div className="flex flex-col justify-between flex-grow">

                        <div className='flex flex-col gap-2.5 w-full'>
                            <div className='text-[#060606] font-medium text-base w-full flex'>{ t('email') }</div>
                            <input
                                className='bg-white w-full h-[50px] rounded-lg px-4 text-[#060606] font-light outline-none border' dir="ltr"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder={t(`enter_your_email`)}
                            />
                        </div>


                        <div className='flex flex-col gap-2.5 w-full'>
                            <div className='text-[#060606] font-medium text-base w-full flex'>{ t('phone') }</div>
                            <input
                                className='bg-white w-full h-[50px] rounded-lg px-4 text-[#060606] font-light outline-none border' dir="ltr"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                placeholder={t(`enter_your_phone`)}
                            />
                        </div>

                        <div className='flex flex-col gap-2.5 w-full'>
                            <div className='text-[#060606] font-medium text-base w-full flex'>{ t('title') }</div>
                            <input
                                className='bg-white w-full h-[50px] rounded-lg px-4 text-[#060606] font-light outline-none border'
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder={t(`enter_your_subject`)}
                            />
                        </div>

                        <div className='flex flex-col gap-2.5 w-full'>
                            <div className='text-[#060606] font-medium text-base w-full flex'>{ t('content') }</div>
                            <textarea 
                                className='bg-white w-full rounded-lg px-4 py-2 text-[#060606] font-light outline-none border'
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                                rows={4}
                                placeholder={t(`enter_your_message`)}
                            >
                            </textarea>
                        </div>

                    </div>

                </div>
 
                <div className="flex min-h-[70px] border-t border-[#DDDDDD]">
                    <button className="text-[#942CC7] text-base font-normal w-full" 
                        onClick={() => handleSendContactUs()}

                    >
                        { t('save') }
                    </button>
                    <span className="border-r border-[#DDDDDD]"></span>
                    <button
                        className="w-full text-base font-normal"
                        onClick={onClose}
                    >
                        { t('cancel') }
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default ContactUsModal;